
jQuery(function ($) {
  $(".cep").on("change", function() {
    var cep = String($(".cep").val()).replace(/\D/g, '');
    
    if (cep != "") {
      var validacep = /^[0-9]{8}$/;
      if(validacep.test(cep)) {
        $.getJSON("https://viacep.com.br/ws/"+ cep +"/json/?callback=?", function(dados) {
          if (!("erro" in dados)) {
            $(".street").val(dados.logradouro);
            $(".states").val(dados.uf);
            $(".cities").val(dados.localidade);
            $('.states').trigger('change');
          } else {
            alert("CEP não encontrado.");
          }
        });
      }
    }
  });
});