// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("packs/toasts")
require("bootstrap")
require("datatables.net-dt")
require("packs/datatables")
require("@fortawesome/fontawesome-free");
require("jquery-mask-plugin")
require("inputmask");
require("chartkick")
require("chart.js")
require("packs/addresses")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import "chartkick/chart.js"

window.addEventListener('DOMContentLoaded', () => {
  
})

document.addEventListener("turbolinks:load", () => { 
  var maskBehavior = function (val) {
    if (val.replace(/\D/g, '').length === 12) return '+00 (000) 00000-0000';
    else if (val.replace(/\D/g, '').length === 13) return '+00 (00) 00000-0000';
    else if (val.replace(/\D/g, '').length === 14) return '+0 (00) 00000-0000';
    else if (val.replace(/\D/g, '').length === 15) return '+0 (00) 00000-0000';
  };

  var options = { onKeyPress: 
    function(val, e, field, options) {
      field.mask(maskBehavior.apply({}, arguments), options);
    }
  };

  document.addEventListener("turbolinks:load", () => { 
    $('.telefone').mask(maskBehavior, options);
    $('.rg').mask('99.999.999-9');
    $('.cpf').mask('999.999.999-99');
    $('.card_number').mask('9999999999999999'); 
    $('.cnpj').mask('00.000.000/0000-00');
    $('.card_expiration').mask('99/99');
    $('.cep').mask('99999-999');

    $('.select2').select2();
  });
})